<template>
	<div ref="self">
		<div v-if="isError" style="text-align: center">
			<i class="el-icon-error" style="color: #f56c6c; font-size: 50px"></i>
			<p>加载失败！</p>
			<p>({{ error }})</p>
			<el-button @click="getLiset">重试</el-button>
		</div>
		<el-table v-show="!isError" :data="tableData" stripe style="width: 100%">
			<el-table-column label="#" type="index" width="30">
				<template slot-scope="scope">
					{{ scope.row.id }}
				</template>
			</el-table-column>

			<el-table-column label="显示名称" width="180" prop="nickname">
			</el-table-column>

			<el-table-column label="手机号" width="180">
				<template slot-scope="scope">
					{{ scope.row.phoneNumber }}
				</template>
			</el-table-column>

			<el-table-column label="创建日期" width="300">
				<template slot-scope="scope">
					<i class="el-icon-date"></i>
					<span style="margin-left: 10px">{{
						$day.unix(scope.row.createdAt).format("YYYY-MM-DD HH:mm")
					}}</span>
				</template>
			</el-table-column>
			<el-table-column align="right" label="操作">
				<template slot-scope="scope">
					<el-button
						size="mini"
						icon="el-icon-set-up"
						plain
						@click="handleModifyAccess(scope.row)"
						>设置权限</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<div style="margin-top: 1em"></div>
		<el-pagination
			v-show="!isError"
			background
			layout="prev, pager, next"
			:page-count="totalPage"
			:current-page.sync="currentPage"
			@current-change="handleCurrentChange"
		></el-pagination>

		<el-dialog
			title="权限设置"
			:visible="true"
			v-if="currentManagerId > 0"
			@close="handleAccessViewClose"
			width="400px"
		>
			<manager-access-editor v-model="currentManagerAccess" />
			<template slot="footer">
				<el-button size="small" type="primary" @click="handleSaveAccess"
					>保存</el-button
				>
				<el-button size="small" @click="handleAccessViewClose">取消</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
import {
	Table,
	TableColumn,
	Button,
	Pagination,
	Loading,
	Dialog,
	Message,
} from "element-ui";
import ManagerAccessEditor from "../../components/ManagerAccessEditor.vue";
export default {
	name: "ManagerList",
	components: {
		ElTable: Table,
		ElTableColumn: TableColumn,
		ElButton: Button,
		ElPagination: Pagination,
		ElDialog: Dialog,
		ManagerAccessEditor,
	},
	data() {
		return {
			pageSize: 10,
			currentPage: 1,
			totalPage: 0,
			isError: false,
			error: "",
			tableData: [],
			currentManagerAccess: 0,
			currentManagerId: 0,
		};
	},
	async mounted() {
		await this.getLiset();
	},
	methods: {
		async getLiset() {
			let loadingInstance = Loading.service({
				target: this.$refs.self,
				fullscreen: false,
			});
			let res;
			try {
				let { data } = await this.$api.manager.manager.list(
					this.pageSize,
					this.currentPage
				);
				res = data;
			} catch (e) {
				console.error(e);
				this.isError = true;
				this.error = e;
				loadingInstance.close();
				return;
			}

			if (res.code === "OK") {
				this.$set(this, "tableData", res.data.rows);
				this.totalPage = res.data.totalPage;
				this.isError = false;
				this.error = "";
			} else {
				this.isError = true;
				this.error = res.errmsg;
				if (res.code === "INVALID_SESSION") {
					this.error += ", 请重新登录";
				}
			}

			loadingInstance.close();
		},
		handleAccessViewClose() {
			this.currentManagerId = 0;
		},
		handleModifyAccess(row) {
			this.currentManagerId = row.id;
			this.currentManagerAccess = row.access;
		},
		async handleCurrentChange() {
			await this.getLiset();
		},
		async handleSaveAccess() {
			try {
				let { data } = await this.$api.manager.manager.modifyAccess(
					this.currentManagerId,
					this.currentManagerAccess
				);

				if (data.code === "OK") {
					Message.success("保存成功");
					this.currentManagerId = 0
					await this.getLiset();
				} else {
					throw data.errmsg;
				}
			} catch (e) {
				Message.error("保存失败: " + e);
			}
		},
	},
};
</script>

<style>
</style>